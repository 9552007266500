@import "../../Assets/Styles/_vars.scss";
@import "../../Assets/Styles/_mixins.scss";

.checkboxCustom {
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $CommonButton;
    border-color: $CommonButton;
  }

  .ant-checkbox-input:focus+.ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: $CommonButton !important;
  }

  .ant-checkbox-checked::after {
    border: 1px solid $CommonButton;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    @include theme() {
      border-color: black !important;
      ;
    }
  }

  .checkboxTxt {
    @include fluid-type(320px, 1920px, 12px, 16px);

    // color: $checkboxparagraph;
    @include theme() {
      color: theme-get("headerDropdownTxt") !important;
    }

    padding-left: 15px;

    &:hover {
      border-color: $CommonButton;
    }
  }

  .checkboxTxtNew {
    @include fluid-type(320px, 1920px, 12px, 16px);

    // color: $checkboxparagraph;
    @include theme() {
      color: theme-get("headerDropdownTxt") !important;
    }

    padding-left: 0px;
    padding-right: 55px;

    &:hover {
      border-color: $CommonButton;
    }
  }

  .ant-checkbox-inner {
    background-color: $btnTextLight;
  }
}

.ant-checkbox-input:focus+.ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: $CommonButton !important;
}