@import "../../Assets/Styles/_mixins.scss";
@import "../../Assets/Styles/_vars.scss";

.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center,
.apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
  justify-content: space-around !important;
  max-width: 800px !important;
  margin: 0 auto !important;
  gap: 20px;

  @media (max-width: 767px) {
    gap: 15px 0px;
  }

  > div {
    display: flex;
    align-items: center;

    .apexcharts-legend-marker {
      margin-right: 10px;
      top: -2px !important;
    }

    span {
      @include theme() {
        color: theme-get("headerDropdownTxt") !important;
      }
    }
  }
}

.apexcharts-canvas {
  margin: 0 auto;

  text {
    @include fluid-type(320px, 1920px, 8px, 20px);

    @include theme() {
      fill: theme-get("headerDropdownTxt") !important;
    }
  }
}

.item {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;

  a {
    @include theme() {
      color: theme-get("headerDropdownTxt");
    }

    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
  }
}

.disabled-page {
  color: #808e9b;
}

.active {
  border: solid 1px;
  border-radius: 0;

  background-color: $CommonButton;
  border-color: $CommonButton;

  a {
    color: $black !important;
  }
}

.a {
  color: "red";
}

.next {
  font-size: 4px;
  height: 40px;
  width: 40px;
  background-color: $CommonButton;
  border-color: $CommonButton;

  a {
    color: $black !important;
  }
}

.pagination {
  align-items: center;
  display: flex;
  padding: 0;
  max-width: 350px;
  width: 100%;
  margin: 0 auto;
  gap: 10px;
  height: 60px;
  justify-content: center;
  list-style-type: none;
  position: relative;

  @media (max-width: 480px) {
    overflow: scroll;
  }
}

.pagination-page {
  font-weight: 600;
  font-family: $baseFont !important;
}

.previous {
  font-size: 4px;
  height: 40px;
  left: 0;
  width: 40px;
  border: solid 1px;
  border-radius: 0;
  background-color: $CommonButton;
  border-color: $CommonButton;
  // @include theme() {
  //   border-color: theme-get("yellowBtn");
  //   background-color: theme-get("yellowBtn");
  // }

  a {
    color: $black !important;
  }
}

.apexcharts-legend.apexcharts-align-center.apx-legend-position-right {
  right: 35px !important;
}

.addnewSec {
  display: flex;
  gap: 20px;

  @media (max-width: 580px) {
    display: block;

    .drop_icon {
      width: 100%;
      min-width: none;
    }

    button {
      width: 100% !important;
      min-width: none;
      border-radius: 5px;
      margin: 5px 0px;
    }
  }

  .ant-space-item {
    @include theme() {
      color: theme-get("darkWhite");
    }
  }
}
