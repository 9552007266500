@import "../../Assets/Styles/_mixins.scss";
@import "../../Assets/Styles/_vars.scss";

.subAdmin {
  .tabs__body {
    margin-top: 0 !important;
  }
}

//UserAccess//
.userAccess {
  margin-top: 15px;

  &_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 15px;

    .userAccess_head-buttons {
      display: flex;
      gap: 10px;
    }

    &_right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 15px;

      .selectuserAcces {
        border-radius: 4px;
        width: 120px;

        .ant-select-selector {
          border-radius: 4px !important;
          width: 170px !important;

        }
      }

    }

    &_addBtn {
      border: 0;
      background-color: transparent;
      cursor: pointer;

      .anticon {
        font-size: 30px;

        @include theme() {
          color: theme-get("darkWhite");
        }
      }
    }
  }
}

.ant-space-item {
  span {
    white-space: nowrap;
  }
}

.formLabel {
  display: block;
  padding-bottom: 10px;
  white-space: nowrap;
  @include fluid-type(320px, 1920px, 12px, 14px);

  @include theme() {
    color: theme-get("whiteblackBase");
  }

  &.pb-0 {
    padding-bottom: 0px;
    font-weight: 500;
  }

}

.radioGroupDiv {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  .ant-radio-group {
    flex: 1;
  }
}

.ant-checkbox-group {
  display: flex !important;
  flex-direction: column;
  gap: 10px;

  .ant-checkbox-wrapper {
    margin: 0 !important;
    @include fluid-type(320px, 1920px, 12px, 14px);

    @include theme() {
      color: theme-get("whiteblackBase");
    }
  }
}

.tableInineFlexed {
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: normal !important;
}

.name_spacing {
  margin-bottom: 0;
}

.userpanding {
  color: #bd7008;
  font-size: 13px;

}

.useractive {
  color: green;
  font-size: 13px;

}

.userExpired {
  color: red;
  font-size: 13px;

  span {
    color: blue;

  }

  a {
    border-bottom: 1px solid;
  }
}

.error {
  color: red;
}

.checkBoxContainer {
  margin-left: 10px;
}

.user-access-custom {
  .ant-table {
    width: 100%;
  }
}

.withLabelLink {
  input {
    width: 16px;
    height: 16px;
    vertical-align: middle;
    cursor: pointer;
  }

  input[type=checkbox] {
    position: relative;
    cursor: pointer;
  }

  input[type=checkbox]:before {
    content: "";
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    top: 0;
    left: 0;
    border: 1px solid #e4e4e4;
    border-radius: 2px;
    background-color: #e4e4e4;
    padding: 1px;
  }

  input[type=checkbox]:checked:after {
    content: "";
    display: block;
    width: 6px;
    height: 11px;
    border: solid rgba(0, 0, 0, .85);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 6px;
  }
}
.darkTheme .checkBoxContainer{
  color: #f5f5f5 !important;
}