@import "../../../Assets/Styles/_mixins.scss";
@import "../../../Assets/Styles/_vars.scss";
.proposal {
  &_headTitle {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: start;
    align-content: center;
    flex-flow: wrap;
    .yellowBtn {
      max-width: 300px;
      padding: 3px 20px;
      height: 45px;
    }
  }
  &_addToken {
    display: flex;
    flex-direction: column;
    // gap: 20px;
    margin-bottom: 20px;
    input {
      max-width: 318px;
    }
  }
  .head {
    margin-top: 20px;
    input {
      max-width: 318px;
      width: 100%;
    }
  }
  .ant-upload {
    max-width: 200px;
    width: 100%;
    button {
      width: 100%;
      height: 40px;
      background-color: transparent;
      border: 1px solid;
      border-radius: 5px;
      @include theme() {
        border-color: theme-get("inputborder");
      }
      @include theme() {
        color: theme-get("whiteblackBase");
      }
    }
  }
  .addFeature {
    margin: 20px 0;
    .deleteOption {
      padding-bottom: 0;
    }
  }
  .ant-space {
    width: 100%;
  }
  .ant-picker {
    width: 100%;
    height: 45px;
    border-radius: 5px !important;
    @include theme() {
      border-color: theme-get("inputborder");
    }
  }
  p {
    padding-bottom: 10px;
    margin: 0;
    color: $titlehead;
    @include theme() {
      color: theme-get("securitypage1");
    }
  }
}
.ant-picker-body {
  table {
    th {
      background-color: transparent !important;
    }
  }
}
.galleyCol {
  img {
    &:last-child {
      height: 112px;
    }
  }
}
.ck-powered-by {
  display: none;
}
.selectSec {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  max-width: 270px;
  position: relative;
  background-color: #bec3ca;
  border: 1px solid #bec3ca;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  height: 40px;
}
.selectSec:before {
  content: "Upload";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  font-size: 14px;
  width: 100%;
  text-align: center;
}
.selectSec input {
  opacity: 0;
  max-width: 270px;
  width: 100%;
}
.deleteDocImage {
  cursor: pointer;
  vertical-align: top;
  margin-right: 8px;
}
.propsalInnerInput {
  align-items: end !important;
  margin-top: 10px;
  .deleteOption {
    padding-bottom: 0;
    button {
      height: 45px;
      // background-color: #3d3d3d !important;
    }
  }
  .uploadpropsal {
    display: flex;
    align-items: end;
    gap: 15px;
    .selectSec {
      height: 45px;
    }
  }
  .marngintop {
    .selectSec {
      margin-top: 27px;
    }
  }
  .error {
    position: absolute;
  }
}
.marginBottom {
  margin-bottom: 30px;
}
.addBanner {
  padding-top: 10px;
}
.descriptionText {
  font-size: 18px;
  font-weight: 600;
  @media (max-width: 480px) {
    font-size: 16px;

    font-weight: 400px;
  }
}
.proposalDescription {
  margin-top: 20px;
  label {
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 10px;
  }
}
.descriptCard {
  gap: 10px;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  &_head {
    display: flex;
    align-items: end;
    gap: 10px;
    margin-bottom: 10px;
    h6 {
      font-size: 22px;
      margin: 0;
      @include theme() {
      color: theme-get("darkWhite");
      }
    }
  }
  &_paragraph {
    font-weight: 300;
  }

  @include theme() {
    background-color: theme-get("headerBackground");
  }
  P {
    margin: 0;
  }
}
