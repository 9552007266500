@import "../../Assets/Styles/_mixins.scss";
@import "../../Assets/Styles/_vars.scss";

.comnModal {
  .ant-modal-content {
    @include theme() {
      background-color: theme-get("modalBg");
      color: theme-get("titlehead");
    }
  }

  .ant-modal-close-x {
    svg path {
      @include theme() {
        color: theme-get("titlehead");
      }
    }
  }

  .ant-modal-header {
    @include theme() {
      background-color: theme-get("modalBg");
      color: theme-get("titlehead");
    }

    border-bottom: 0;

    .ant-modal-title {
      font-family: $baseFont !important;

      @include theme() {
        color: theme-get("titlehead");
      }
    }
  }
}

.ant-modal-wrap {
  backdrop-filter: blur(6px) saturate(150%);
  // background-color: rgba(75, 75, 75, 0);
}