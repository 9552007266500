@import "../../Assets/Styles/_mixins.scss";
@import "../../Assets/Styles/_vars.scss";
.cards {
  max-width: 400px;
  width: 100%;
  @media (max-width: 767px) {
    max-width: 100%;
  }

  &_vertual {
    background-image: url("../../Assets/Images/cardwithicon.png");
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 394px;
    width: 100%;
    height: 248px;
    position: relative;

    @media (max-width: 1120px) {
      max-width: 100%;
      height: 210px;
      background-size: contain;
      margin: auto;
    }

    @media (max-width: 767px) {
      max-width: 450px;
      height: 300px;
      background-size: contain;
      margin: auto;
    }

    h5 {
      padding: 14px 0px 0px 20px;
      font-size: 16px;

      @media (max-width: 480px) {
        font-size: 12px;
      }
    }

    p {
      position: absolute;
      top: 60%;
      left: 15%;
      font-size: 20px;

      @media (max-width: 380px) {
        top: 45%;
        font-size: 16px;
      }
    }
  }

  &_detail {
    // max-width: 400px;
    width: 100%;
    // background: $btnBaseColor;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 1px;
    border-radius: 20px;
    margin-top: 20px;
    @include theme() {
      background: theme-get("headerBackground") !important;
    }
    &:first-child {
      margin-top: 0;
    }

    @media (max-width: 767px) {
      max-width: 450px;
      margin: auto;
    }

    ul {
      list-style: none;
      padding-left: 0;

      li {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        p {
          margin-bottom: 0;
          margin-bottom: 10px;
          @include theme() {
            color: theme-get("headerDropdownTxt");
          }

          &:nth-last-child(1) {
            font-weight: 500;
          }
        }
      }
    }
  }
}
