@import "../../Assets/Styles/_vars.scss";
@import "../../Assets/Styles/_mixins.scss";

.controlsCard {
  padding: 20px;

  ul {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &:last-child {
      display: flex;
    }

    li {
      display: block;
      justify-content: space-between;

      .inputLayout {
        width: 100%;
      }

      .inputCustum {
        padding-right: 55px;
      }

      label {
        padding: 0;
        font-size: 16px;
      }
    }
  }

  p {
    font-size: 18px;
    font-weight: 400;
  }
}

.minted {
  margin-top: 15px;
  display: flex;
  gap: 40px;
  align-items: center;

  @media (max-width: 767px) {
    justify-content: center;
  }

  @include theme() {
    color: theme-get("whiteblackBase");
  }

  p {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
  }
}

.submitBtn {
  max-width: 1071px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.usdtInput {
  max-width: 502px;
  width: 100%;
  margin-top: 15px;
  position: relative;

  label {
    font-size: 18px;
    font-weight: 500;
  }

}

.usdt {
  font-size: 18px;
  font-weight: 500;
  position: absolute;
  bottom: 9px;
  right: 10px;
  margin-bottom: 0;

  @include theme() {
    color: theme-get("whiteblackBase");
  }
}

.error {
  color: red !important;
}