@import "../../Assets/Styles/_vars.scss";

.agreementBtn {
  display: flex;
  gap: 10px;
  justify-content: center;

  button {
    background-color: $traditionalTypes__green;
    color: $white;
    border: none;
    cursor: pointer;
    padding: 8px;
    border-radius: 8px 10px;
  }
  .declineBtn {
    background-color: $redbutton;
  }
}
.ApprovalRecquest-style {
  table {
    svg {
      width: 25px;
      height: 25px;
      cursor: pointer;
    }
    th,
    td {
      &:nth-child(7) {
        text-align: center !important;
      }
      &:last-child {
        text-align: center;
      }
    }
  }
}
.disabled {
  &.disabled {
      opacity: 0.4;
      cursor: not-allowed;
  }
}
.ml-50{
  margin-left: 50px;
}