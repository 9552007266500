@import "../../Assets/Styles/_mixins.scss";
@import "../../Assets/Styles/_vars.scss";
.cryptoPortfolio {
  &_link {
    gap: 20px;
    align-items: center;
    .ant-picker {
      max-width: 250px;
    }
    a {
      white-space: nowrap;
      @include theme() {
        color: theme-get("titlehead");
      }
    }
  }
}
.disabled {
  &.disabled {
      opacity: 0.4;
      cursor: not-allowed;
  }
}
.download_btn{
  cursor: pointer !important;
}