@import "../../../Assets/Styles/_mixins.scss";
@import "../../../Assets/Styles/_vars.scss";
.makePaymentCard {
  max-width: 300px;
  width: 100%;
  border-radius: 5px;
  padding: 34px 0 40px;
  @include theme() {
    background-color: theme-get("headerBackground");
    color: theme-get("whiteblackBase");
  }
  &_top {
    padding: 0 40px 20px;
    @include theme() {
      background-color: theme-get("headerBackground");
      color: theme-get("whiteblackBase");
    }
    border-bottom: 1px solid $CommonButton;
    display: flex;
    justify-content: space-between;
  }
  &_bottom {
    padding: 26px 40px 0;
  }
}
