@import "../../Assets/Styles/_mixins.scss";
@import "../../Assets/Styles/_vars.scss";

div {
  @include theme() {
    background-color: theme-get("primaryBg");
  }
}

.dashboardScreen {
  .responsive {
    display: flex;
    gap: 20px;
    flex-flow: wrap;
    margin-bottom: 20px;
  }

  &_middleCard {
    .balanceCard {
      display: flex;
      justify-content: space-between;
      // flex-flow: wrap;
      gap: 20px;

      @media (max-width: 767px) {
        flex-flow: wrap;
      }

      &_card {
        width: 100%;
        padding: 20px;
        border-radius: 10px;

        @include theme() {
          background-color: theme-get("headerBackground");
        }

        ul {
          margin: 0;
          padding: 0;

          li {
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 25px;
            margin: 0;

            @include theme() {
              color: theme-get("headerDropdownTxt");
            }

            @media (max-width:460px) {
              flex-direction: column;
            }

            p {
              margin: 0;
              font-size: 16px;
              font-weight: 400;
              white-space: nowrap;

              @include theme() {
                color: theme-get("headerDropdownTxt");
              }
            }

            :last-child {
              font-weight: 500;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  &_totalUsers {
    // display: flex;
    // gap: 20px;
  }

  &_cards {
    @include theme() {
      background-color: theme-get("headerBackground");
    }

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-direction: row;
    gap: 20px;
    max-width: 306px;
    width: 100%;
    padding: 20px 10px;
    border-radius: 10px;
    // margin: 0 auto;
    min-height: 100px;

    div {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;

      >div {
        @include theme() {
          color: theme-get("headerDropdownTxt");
        }

        @include fluid-type(320px, 1920px, 14px, 16px);
        font-weight: 500;

        &:first-child {
          font-weight: normal !important;
          font-family: $baseFont !important;
        }
      }
    }
  }

  &__balance {
    padding: 20px;
  }

  &__reward {
    // height: 100%;
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }

  &_bottomRow {
    .__apiManageSection {
      height: 61px;
      border-radius: 10px;
      margin-right: 20px;
      margin-left: 10px;
      margin-top: 20px;
      display: flex;
      align-items: center;

      @include theme() {
        background-color: theme-get("headerBackground");
      }

      &__left {
        width: 75%;
        text-align: left;
        font-size: 16px;

        @include theme() {
          color: theme-get("headerDropdownTxt");
        }

        font-weight: 500;
        padding-left: 30px;
      }

      &__image {
        position: absolute;
        margin-left: 20px;
        margin-top: 1px;
      }

      .ant-btn {
        text-align: right;
        width: 84px;
        height: 31px;
        border: none;
        border-radius: 5px;

        @include theme() {
          background-color: theme-get("grayBtnBackground");
          color: theme-get("headerDropdownTxt");
        }
      }
    }

    .__openOrdersSection {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 20px;
    }
  }

  @media (max-width: 1440px) {
    .__apiManageSection {
      &__left {
        width: 65%;
      }
    }
  }

  @media (max-width: 1200px) {
    .__apiManageSection {
      height: 82px;

      &__left {
        width: 58%;
      }

      &__image {
        margin-left: 10px;
      }
    }
  }

  @media (max-width: 720px) {
    .__apiManageSection {
      &__left {
        width: 45%;
        padding-left: 10px;
      }
    }
  }

  @media (max-width: 576px) {
    .ant-row {
      display: flex;
      flex-direction: column;
    }

    .__apiManageSection {
      width: 347px;
      margin-left: 20px;
      height: 61px;

      &__left {
        width: 70%;
        padding-left: 30px;
      }
    }
  }

  //   @media (max-width: 575px) {
  //     .responsive {
  //       margin-left: auto;
  //       margin-right: auto;
  //     }
  //   }
  @media (max-width: 375px) {
    .__apiManageSection {
      margin-left: 10px;
    }

    &__balance {
      padding-left: 10px;
    }
  }

  @media (max-width: 320px) {
    .__apiManageSection {
      margin-left: 20px;
    }

    &__balance {
      padding-left: 20px;
    }
  }

  @media (max-width: 371px) {
    .__apiManageSection {
      width: 273px;

      &__left {
        width: 60%;
      }
    }
  }

  &__chartSec {
    margin-top: 24px;
    margin: 50px 0 0;
    width: 100%;
    padding: 20px;
    border-radius: 10px;

    @include theme() {
      background-color: theme-get("headerBackground");
    }

    @media (max-width: 767px) {
      padding: 20px 0px;
    }
  }

  &__dateSelect {
    margin-bottom: 50px;
    display: flex;
    justify-content: flex-end;
  }

  .portfolioTabs {
    .overallCrypto {
      &_detail {
        max-width: 700px;
        width: 100%;

        &_link {
          display: flex;
          justify-content: space-between;
          gap: 10px;

          .ant-picker {
            max-width: 250px;
          }

          a {
            white-space: nowrap;
            text-decoration: underline;
            @include fluid-type(320px, 1920px, 14px, 16px);
            font-weight: 500;

            // color: $titlehead;
            @include theme() {
              color: theme-get("titlehead");
            }

            &:hover {
              color: rgb(85, 167, 249);
            }
          }
        }
      }

      h4 {
        @include fluid-type(320px, 1920px, 14px, 20px);
        margin-top: 15px;

        @include theme() {
          color: theme-get("headerDropdownTxt");
        }
      }

      .cards_detail {
        max-width: 100% !important;

        ul {
          li {
            @include theme() {
              border-bottom: 1px solid theme-get("inputborder1") !important;
            }

            padding: 15px;

            p {
              margin: 0;
              @include fluid-type(320px, 1920px, 14px, 16px);
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

.dashboardScreen1 {
  &_cards {
    @include theme() {
      background-color: $CommonButton;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding: 20px 10px;
    border-radius: 10px;
    margin: 0 auto;
    min-height: 100px;

    div {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;

      >div {
        color: black;

        @include fluid-type(320px, 1920px, 14px, 16px);
        font-weight: 500;

        &:first-child {
          font-weight: normal !important;
          font-family: $baseFont !important;
        }
      }
    }
  }

  &__balance {
    padding: 20px;
  }

  &__reward {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
  }

  &_bottomRow {
    .__apiManageSection {
      height: 61px;
      border-radius: 10px;
      margin-right: 20px;
      margin-left: 10px;
      margin-top: 20px;
      display: flex;
      align-items: center;

      @include theme() {
        background-color: theme-get("headerBackground");
      }

      &__left {
        width: 75%;
        text-align: left;
        font-size: 16px;

        @include theme() {
          color: theme-get("headerDropdownTxt");
        }

        font-weight: 500;
        padding-left: 30px;
      }

      &__image {
        position: absolute;
        margin-left: 20px;
        margin-top: 1px;
      }

      .ant-btn {
        text-align: right;
        width: 84px;
        height: 31px;
        border: none;
        border-radius: 5px;

        @include theme() {
          background-color: theme-get("grayBtnBackground");
          color: theme-get("headerDropdownTxt");
        }
      }
    }

    .__openOrdersSection {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 20px;
    }
  }

  @media (max-width: 1440px) {
    .__apiManageSection {
      &__left {
        width: 65%;
      }
    }
  }

  @media (max-width: 1200px) {
    .__apiManageSection {
      height: 82px;

      &__left {
        width: 58%;
      }

      &__image {
        margin-left: 10px;
      }
    }
  }

  @media (max-width: 720px) {
    .__apiManageSection {
      &__left {
        width: 45%;
        padding-left: 10px;
      }
    }
  }

  @media (max-width: 576px) {
    .ant-row {
      display: flex;
      flex-direction: column;
    }

    .__apiManageSection {
      width: 347px;
      margin-left: 20px;
      height: 61px;

      &__left {
        width: 70%;
        padding-left: 30px;
      }
    }
  }

  //   @media (max-width: 575px) {
  //     .responsive {
  //       margin-left: auto;
  //       margin-right: auto;
  //     }
  //   }
  @media (max-width: 375px) {
    .__apiManageSection {
      margin-left: 10px;
    }

    &__balance {
      padding-left: 10px;
    }
  }

  @media (max-width: 320px) {
    .__apiManageSection {
      margin-left: 20px;
    }

    &__balance {
      padding-left: 20px;
    }
  }

  @media (max-width: 371px) {
    .__apiManageSection {
      width: 273px;

      &__left {
        width: 60%;
      }
    }
  }

  &__chartSec {
    margin-top: 24px;
    margin: 50px 0 0;
    width: 100%;
    padding: 20px;
    border-radius: 10px;

    @include theme() {
      background-color: theme-get("headerBackground");
    }

    @media (max-width: 767px) {
      padding: 20px 0px;
    }
  }

  &__dateSelect {
    margin-bottom: 50px;
    display: flex;
    justify-content: flex-end;
  }
}

.ant-space {
  @media (max-width: 767px) {
    width: 100%;
  }
}

.ant-picker {
  background-color: transparent !important;
  border-radius: 8px !important;

  @media (max-width: 767px) {
    width: 100%;
  }
}

.ant-picker {
  background-color: transparent !important;
  border-radius: 8px !important;

  @media (max-width: 767px) {
    width: 100%;
  }

  @include theme() {
    border-color: theme-get("inputborder") !important;
  }

  .anticon {
    @include theme() {
      color: theme-get("headerDropdownTxt");
    }
  }

  input {
    @include theme() {
      color: theme-get("headerDropdownTxt");
    }
  }

  &-input {
    .ant-picker-clear {
      @include theme() {
        background-color: theme-get("headerBackground");
      }
    }
  }
}

.ant-picker-clear:hover {
  @include theme() {
    color: theme-get("headerDropdownTxt") !important;
  }

  background: transparent;
}

.apexcharts-legend-text {
  @include theme() {
    color: theme-get("headerDropdownTxt") !important;
  }
}

.ml-5 {
  margin-left: 5px;
}