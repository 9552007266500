@import "../../Assets/Styles/_vars.scss";
@import "../../Assets/Styles/_mixins.scss";

.footer {
  padding: 15px;
  text-align: center;
  width: 100%;
  &__text {
    &__copyrightIcon {
      margin-right: 5px;
    }
    padding-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    @include theme() {
      color: theme-get("footerTextCopyright");
    }
    @include fluid-type(320px, 1920px, 12px, 13px);
    font-weight: normal;
    transition: 0.3s all;
    &__spanText {
      margin-left: 23px;
    }
  }
  &_flexStyle {
    @media (max-width:767px) {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }
}
