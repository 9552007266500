@import "../../Assets/Styles/_mixins.scss";
@import '../../Assets/Styles/_vars.scss';

.commoanSide {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    ;
}

.commonPaddingNew {
    padding-left: 30px;
    padding-right: 30px;
}

.transec_histry {
    &__heading {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        @media (max-width:580px) {
            display: block;
        }

        .ant-select {

            border-radius: 5px;
            height: 32px;
        }

        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
            padding: 0.5px 16px !important;
        }

        .addnewSec {
            display: flex;
            gap: 20px;

            @media (max-width:580px) {
                display: block;

                .drop_icon {
                    width: 100%;
                    min-width: none;

                }

                button {
                    width: 100% !important;
                    min-width: none;
                    border-radius: 5px;
                    margin: 5px 0px;
                }
            }

            .ant-space-item {
                @include theme() {
                    color: theme-get("darkWhite");
                }
            }
        }

        h2 {
            @include theme() {
                color: theme-get("darkWhite");

            }
        }

    }
}


.topdropdown {
    @include theme() {
        background-color: theme-get("securityBtn");
    }

}