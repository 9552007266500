@import "../../Assets/Styles/_mixins.scss";
@import "../../Assets/Styles/_vars.scss";

.appCustomisation {
  .tabs__body {
    margin-top: 0 !important;
  }

  .imgsec {
    display: flex;
    align-items: center;
    gap: 15px;
  }
}

.addnewSec {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;

  button {
    // @include theme() {
    //   background-color: theme-get("cmnBtnColor") !important;
    // }
    background-color: $CommonButton !important;
    color: $white !important;
    padding: 7px 20px;
    border: 0;
    min-width: 150px;
    width: auto !important;
    cursor: pointer;
    position: relative;
  }
}

.modalApp {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .addnewSec {
    justify-content: center;
  }
}

.modalApp {
  .addnewSec button {
    background-color: $CommonButton !important;
    color: black !important;
  }
}

.copyIcon {
  cursor: pointer;
}

.coinImageText {
  display: flex;

  @include theme() {
    color: theme-get("selectTxtColor");
    background-color: theme-get("inputborder");
  }

  text-align: center;
  align-self: center;
  font-weight: 500;
  height: 45px;
  width: 45px;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  margin-left: 25px;
  padding-top: 3px;
}

.addnewSec1 {
  justify-content: space-between;
  flex-direction: row;
}

.anticon.anticon-search {
  @include theme() {
    color: theme-get("selectTxtColor");
    // background-color: theme-get("inputborder");
  }
}
.disabled {
  &.disabled {
      opacity: 0.4;
      cursor: not-allowed;
      .fontFamilyText{
        color: black;
      }
  }
}