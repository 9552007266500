@import "../../Assets/Styles/_mixins.scss";
@import "../../Assets/Styles/_vars.scss";
div {
  @include theme() {
    background-color: theme-get("primaryBg");
  }
}
.forgotScreen {
  width: 100%;
  &__multiBtn {
    display: flex;
    
  }
  &__innerInput {
    padding-bottom: 26px;
  }
  &__selectInput {
    display: flex;
    gap: 10px;
    .inputLayout {
      width: 100%;
    }
  }
  &__selectlabel {
    letter-spacing: 0px;
    @include theme() {
      color: theme-get("titlehead");
    }
    opacity: 1;
    width: 100%;
    text-align: left;
    @include fluid-type(320px, 1920px, 12px, 14px);
    display: flex;
    padding-bottom: 10px;
  }
  &__logBtn {
    background: transparent !important;
    @include theme() {
      color: theme-get("titlehead") ;
    }
    margin-bottom: 26px;
    width: auto !important;
    gap: 28px;
    margin-right: 30px;

    &:hover {
      @include theme() {
        background-color: theme-get("grayBtn") !important;
      }
      color: $white !important;
    }
  }
  &__activeBtn {
    color: $white !important;
    @include theme() {
      background-color: theme-get("grayBtn") !important;
     
    }
    &:focus {
      box-shadow: unset;
      border: 0;
      color: $white !important;
    }
  }
  &__rytCol {
    h2 {
      @include theme() {
        color: theme-get("titlehead") !important;
      }
      @include fluid-type(320px, 1920px, 16px, 36px);
      font-weight: 600;
      margin-bottom: 30px;
    }
  }
}
