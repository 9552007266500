@import "../../Assets/Styles/_mixins.scss";
@import "../../Assets/Styles/_vars.scss";

.supportManagement {
  &_ticket {
    h2 {
      font-size: 18px;
      font-weight: 500;

      @include theme() {
        color: theme-get("whiteblackBase");
      }
    }

    &_selects {
      display: flex;
      gap: 20px;
      flex-flow: wrap;

      .inputLayout,
      .SelectOuter,
      .selector {
        @media (max-width: 1068px) {
          flex: 100%;
        }

        @media (max-width: 991px) {
          flex: 30%;
        }

        @media (max-width: 720px) {
          flex: 100%;
        }
      }
    }

    &_details {
      white-space: nowrap;
      margin-top: 20px;
      padding-left: 15px;
      display: flex;
      align-items: center;
      gap: 20px;
      width: 100%;
      height: 60px;
      border: 1px solid $staticBorder;
      border-radius: 5px;
      overflow: scroll;

      p {
        margin: 0;
        font-size: 18px;
        font-weight: 300;

        @include theme() {
          color: theme-get("whiteblackBase");
        }
      }
    }
  }
}

.snapShot {
  img {
    height: auto;
    max-width: 200px;
  }
}

.modalClose_btn_left {
  text-align: right;
}

.drop_icon1 {
  .ant-dropdown-trigger {
    height: 45px;
    border-radius: 4px;
    background-color: $CommonButton !important;
    color: #181a20 !important;

  }
}

.view {
  label {
    margin-top: 10px;

    @include theme() {
      color: theme-get("whiteblackBase");
    }
  }

  .upload {
    margin-top: 10px;

    .inner {
      display: flex;
      gap: 20px;
      margin-top: 10px;

      @media (max-width: 500px) {
        flex-wrap: wrap;
      }

      .ant-upload {
        width: 212.5px;
        height: 104px;

        .ant-btn {
          width: 212.5px;
          height: 104px;
          border: unset;
          border: 1px dashed;

          @include theme() {
            color: theme-get("whiteblackBase");
          }

          background-color: transparent;
        }
      }

      .ant-btn:hover {
        @include theme() {
          color: theme-get("whiteblackBase");
        }

        border: 1px dashed !important;
      }

      .ant-btn:active,
      :focus {
        border: 1px dashed !important;
        color: #000 !important;
      }
    }
  }
}