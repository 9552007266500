@import "../../Assets/Styles/_mixins.scss";
@import "../../Assets/Styles/_vars.scss";
div {
  @include theme() {
    background-color: theme-get("primaryBg");
  }
}
.loginDetail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  @media (max-width: 767px) {
    align-items: center;
    margin-bottom: 30px;
    height: auto;
  }
  &__inner_Text {
    h2 {
      @include theme() {
        color: theme-get("titlehead");
      }
      opacity: 1;
      @include fluid-type(320px, 1920px, 14px, 20px);
    }
    p {
      @include theme() {
        color: theme-get("titlehead");
      }
      width: 100%;
      margin: 0 auto;
      max-width: 290px;
      opacity: 1;
      @include fluid-type(320px, 1920px, 12px, 14px);
      span {
        color: $CommonButton;
      }
    }
    .largehead {
      @include fluid-type(320px, 1920px, 15px, 30px);
      @include theme() {
        color: theme-get("titlehead");
      }
      opacity: 1;
    }
    @media (max-width: 767px) {
      display: none !important;
    }
  }
  &__qrlogo {
    padding-bottom: 20px;
  }
}
