// @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "./Components/Assets/Styles/_mixins.scss";
@import "./Components/Assets/Styles/_vars.scss";

body {
  margin: 0;
  font-family: $baseFont !important; // "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  // font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  font-family: $baseFont;
}

#root {
  height: 100%;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
div,
button,
input,
optgroup,
select,
textarea {
  font-family: $baseFont !important;
}

.darkTheme {
  background-color: #181a20;

  .borderItem {
    img {
      filter: invert(1);
    }
  }

  .ck.ck-editor__editable_inline> :last-child {
    color: $white;
  }

  .ant-menu-item {
    span {
      svg {
        filter: invert(1);
      }
    }
  }

  .disHead {
    filter: invert(1);
  }
}

.lightTheme {
  background-color: #fafafa;
}

.ant-layout {
  @include theme() {
    background-color: theme-get("bgSection");
  }
}

.ant-layout-content {
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .flexAuto {
    flex: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.marginTop20 {
  margin-top: 20px !important;
}

.container {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

.container_landing {
  width: 100%;
  max-width: 1368px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

// Common csss----------------------
.orAdd {
  position: relative;
  text-align: center;
  color: $titlehead;
  margin-top: 26px;

  @include theme() {
    color: theme-get("titlehead");
  }

  &::before {
    position: absolute;
    height: 1px;
    width: 100%;
    content: "";
    background-color: $grayLightTxt;
    max-width: 150px;
    // transform: translateY(-50%);
    top: 50%;
    right: 0;
    opacity: 1;

    @media (max-width: 424px) {
      max-width: 125px;
    }
  }

  &::after {
    position: absolute;
    height: 1px;
    width: 100%;
    content: "";
    background-color: $grayLightTxt;
    max-width: 150px;
    // transform: translateY(-50%);
    top: 50%;
    left: 0;
    opacity: 1;

    @media (max-width: 424px) {
      max-width: 125px;
    }
  }
}

// Sidebar table css common_________________________

// .orderCmnTable {
// padding-top: 30px;
.ant-table {
  background-color: transparent;
  // height: 400px;
  overflow: auto;
}

thead {
  position: sticky;
  top: 0;
  z-index: 1;

  tr th {
    @include theme() {
      background-color: theme-get("OpenHead");
    }

    color: $grayLightTxt;
    border-bottom: 0 !important;
    white-space: nowrap;
  }

  @include fluid-type(320px, 1920px, 10px, 14px);

  color: $grayLightTxt;
  border-bottom: 0 !important;
  white-space: nowrap;
}

tbody tr {
  &:last-child {
    td {
      border-bottom: 0;
    }
  }
}

td {
  background-color: transparent;

  // @include theme() {
  //   border-color: theme-get("textDark");
  // }

  color: $grayLightTxt;
  // border-bottom: 0 !important;
  border-color: grey !important; // $CommonButton !important;
  @include fluid-type(320px, 1920px, 10px, 14px);
}

.ant-table-tbody>tr.ant-table-placeholder:hover>td {
  background: transparent;
}

.ant-empty {
  .ant-empty-description {
    color: $grayLightTxt;
  }
}

// }

// Common csss----------------------End

//sidebar css starts here //

.ant-layout-sider {
  border-right: 1px solid;

  @include theme() {
    background-color: theme-get("bgSection");
    border-color: theme-get("sidebarRightBorder");
  }

  &-trigger {
    border: 1px solid;

    @include theme() {
      background-color: theme-get("bgSection");
      border-color: theme-get("sidebarRightBorder");
      color: theme-get("darkWhite");
    }
  }
}

.ant-menu {
  &-item {
    padding-left: 30px !important;

    &-icon {
      display: flex !important;
      height: 100% !important;
      align-items: center !important;
    }

    margin-top: 0 !important;

    @include theme() {
      color: theme-get("headerDropdownTxt") !important;
    }

    &-selected {

      // background-color: $CommonButton !important;
      @include theme() {
        background-color: theme-get("sidebarBackground") !important;
        color: theme-get("headerDropdownTxt") !important;
      }
    }

    &:hover {
      @include theme() {
        background-color: theme-get("sidebarActiveItem") !important;
      }
    }

    &-only-child {
      position: relative;
      padding-left: 80px !important;

      &::before {
        content: "";
        // position: absolute;
        left: 60px;
        top: 50%;
        transform: translateY(-50%);
        width: 5px;
        height: 5px;
        border-radius: 100%;

        @include theme() {
          background-color: theme-get("headerDropdownTxt") !important;
        }
      }
    }
  }

  @include theme() {
    background-color: theme-get("bgSection") !important;
    color: theme-get("headerDropdownTxt") !important;
  }

  &-title-content,
  &-title-content a {
    @include theme() {
      color: theme-get("headerDropdownTxt") !important;
    }
  }

  &-submenu {
    &-arrow {

      &::before,
      &::after {
        @include theme() {
          background-color: theme-get("headerDropdownTxt") !important;
        }
      }
    }

    &-title {
      margin-top: 0 !important;
      padding-left: 30px !important;

      &:hover {
        @include theme() {
          color: theme-get("headerDropdownTxt") !important;
        }
      }
    }

    &:hover {
      @include theme() {
        background-color: theme-get("sidebarActiveItem") !important;
      }
    }

    &-selected {
      @include theme() {
        background-color: theme-get("sidebarActiveItem") !important;
        color: theme-get("headerDropdownTxt") !important;
      }
    }
  }
}

.ant-menu-submenu-popup {
  li {
    padding-left: 30px !important;

    &::before {
      content: unset;
    }
  }
}

.ant-menu.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-item {
  padding: 0 calc(50% - 12px) !important;
}

//KYC Modals//

.KycModals {
  .ant-modal-content {
    @include theme() {
      background-color: theme-get("ModalColor");
    }

    padding: 70px 70px 80px;
    border-radius: 5px;
    height: 723px;

    @media (max-width: 991px) {
      padding: 70px 25px 25px;
      height: auto;
    }
  }

  .ant-modal-body {
    padding: 0;
    height: 100%;
  }

  .anticon {
    svg path {
      fill: $grayLightTxt;
    }
  }
}

.hover__link {
  transition: 0.3s all;

  &:hover {
    color: $CommonButton !important;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield !important;
}

//scrollbar {
::-webkit-scrollbar-track {
  border-radius: 0px;

  @include theme() {
    background-color: theme-get("scrollbarTrack");
  }
}

::-webkit-scrollbar-thumb {
  @include theme() {
    background-color: theme-get("scrollbarThumb");
  }

  border-radius: 3px;
  height: 90px;
}

.scrollBar:hover::-webkit-scrollbar,
::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

input:-internal-autofill-selected {
  @include theme() {
    background-color: theme-get("bgSection");
    color: theme-get("whiteblackBase");
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
  @include theme() {
    background-color: theme-get("bgSection");
    color: theme-get("whiteblackBase");
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  @include theme() {
    background-color: theme-get("bgSection");
    color: theme-get("whiteblackBase");
    -webkit-text-fill-color: theme-get("whiteblackBase");
  }

  -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
}

table td,
th {
  white-space: nowrap;
}

.breadcrumb {
  @include theme() {
    background-color: theme-get("headerBackground");
    color: theme-get("whiteblackBase");
  }

  padding: 18.5px;
  position: sticky;
  top: 0;
  z-index: 99;
  height: 62px;
}

.ant-breadcrumb {
  font-family: $baseFont !important;

  span,
  span>a {
    @include theme() {
      color: theme-get("whiteblackBase");
    }

    font-size: 16px !important;
    font-weight: 500;
  }
}

.ant-menu-inline>.ant-menu-item // .ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title,

// .ant-menu-vertical-left>.ant-menu-item,
// .ant-menu-vertical-left>.ant-menu-submenu>.ant-menu-submenu-title,
// .ant-menu-vertical-right>.ant-menu-item,
// .ant-menu-vertical-right>.ant-menu-submenu>.ant-menu-submenu-title,
// .ant-menu-vertical>.ant-menu-item,
// .ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title
  {
  @media (min-width: 991px) {
    height: 62px !important;
    line-height: 62px !important;
  }
}

.ant-table-tbody>tr.ant-table-row:hover>td {
  background: unset !important;
}

.ant-table-tbody>tr.ant-table-placeholder:hover>td {
  background-color: transparent !important;
}

.ant-table {
  @include theme() {
    background-color: theme-get("headerBackground");
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 9;
    // @include theme() {
    //   background-color: theme-get("theadBg");
    // }
    background-color: $CommonButton;
  }

  border-radius: 8px;
  overflow: auto;

  .ant-table-thead>tr>th {
    background-color: transparent;
    padding: 12px 30px;
    border-bottom: 1px solid !important;
    font-family: $baseFont !important;

    @include theme() {
      border-color: theme-get("headerInputBorder") !important;
    }

    white-space: nowrap;
    // @include theme() {
    //   color: theme-get("headerDropdownTxt");
    // }
    color: black;
    @include fluid-type(320px, 1920px, 14px, 16px);

    &::before {
      content: unset !important;
    }
  }

  td {
    padding: 10px 30px !important;
    @include fluid-type(320px, 1920px, 14px, 16px);

    @include theme() {
      color: theme-get("headerDropdownTxt");
    }

    font-family: $baseFont !important;
    // color: "#b1b5c4" !important;
    font-weight: normal;
  }
}

.deleteOption {
  align-items: end;
  display: flex !important;
  padding-bottom: 16px;

  .yellowBtn {
    max-width: 140px;
    height: 44px;
    background-color: red !important;
    color: #fff !important;
    font-weight: 700;
  }

  .cancelButton {
    margin-left: 10px;
  }
}

.selectorPosItems {
  position: relative;

  .deleteAlign {
    padding-bottom: 0;
  }

  .error {
    position: absolute;
  }
}

.addOpption {
  padding-top: 5px;

  button {
    border: none;
    cursor: pointer;
    background: none;
    background-color: lightgray;
    width: 200px;
    height: 44px;
    padding: 4px 10px;
    border-radius: 5px;

    h2 {
      margin: 0;
    }
  }
}

.buttonView {
  border: 0;
  cursor: pointer;
  @include fluid-type(320px, 1920px, 14px, 16px);
  // @include theme() {
  //   background-color: theme-get("cmnBtnColor") !important;
  // }
  background-color: grey !important; // $CommonButton;
  color: $white !important;
  padding: 8px 15px;
  font-weight: normal;
  border-radius: 8px;
  display: block;
  width: fit-content;
  transition: 0.3s all;

  &:hover {
    opacity: 0.8;
  }

  span>span {
    margin-left: 10px;
  }
}

.tabs {
  &__body {
    margin-left: 0px;
    margin-top: 30px !important;

    .ant-tabs-tab {
      color: $grayLightTxt;
      padding: 10px;
    }

    .ant-tabs-tab-active .ant-tabs-tab-btn {
      // @include theme() {
      //   color: theme-get("headerDropdownTxt");
      // }
      color: black !important;
    }

    .ant-tabs-tab-btn {
      @include theme() {
        color: theme-get("headerDropdownTxt");
      }

      // font-family: $baseFont !important;
    }

    .ant-tabs-ink-bar {
      position: absolute;
      background: $CommonButton;
      pointer-events: none;
      height: 50px !important;
      z-index: -1;
      font-family: $baseFont !important;
      // @include theme() {
      //  background-color: theme-get("cmnBtnColor");
      // }
    }

    .ant-tabs-nav:before {
      @include theme() {
        border-bottom-color: theme-get("sidebarRightBorder");
      }
    }
  }
}

.ant-tabs-tab {

  &:hover,
  &:focus,
  &:active,
  &:focus-visible {
    @include theme() {
      color: theme-get("headerDropdownTxt");
    }
  }
}

.deleteBtn {
  border: 0;
  padding: 0;
  background-color: transparent !important;

  &:hover {
    animation: shake 0.82s cubic-bezier(0.26, 0.07, 0.9, 0.9) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;

    .anticon {
      color: $CommonButton;
    }
  }

  .anticon {
    font-size: 24px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.swal2-popup.swal2-modal {
  font-size: 1rem !important;
  font-family: $baseFont !important;

  @include theme() {
    color: theme-get("titlehead");
  }

  @include theme() {
    color: theme-get("titlehead");
  }

  @include theme() {
    background: theme-get("modalBg");
  }

  .swal2-html-container {
    font-size: 1.4rem !important;
    font-family: $baseFont !important;

    @include theme() {
      color: theme-get("titlehead");
    }
  }
}

.swal2-container {
  .swal2-confirm {
    color: black !important;
  }
}

.swal2-popup .swal2-styled:focus {
  box-shadow: none !important;
}

.swal2-title {
  font-size: 1.4rem !important;
  font-family: $baseFont !important;

  @include theme() {
    color: theme-get("titlehead");
  }
}

// .swal-styled {
//   box-shadow: 0px !important;
// }

.swal-modal {
  font-size: 1.4rem !important;
  font-family: $baseFont !important;

  @include theme() {
    background: theme-get("modalBg");
  }

  .swal-title {
    font-size: 1.4rem !important;
    font-family: $baseFont !important;

    @include theme() {
      color: theme-get("titlehead");
    }
  }

  .swal-button {
    background: $CommonButton;
    color: black;
    font-family: $baseFont !important;

    &.swal-button--cancel {
      @include theme() {
        background: theme-get("selectBorder");
      }

      @include theme() {
        color: theme-get("titlehead");
      }

      border: 1px solid #fff;
    }
  }
}

.swal-text {
  // background-color: #FEFAE3;
  text-align: center;
  font-family: $baseFont !important;

  @include theme() {
    color: theme-get("titlehead");
  }

  font-size: 16px !important;
  margin-top: 15px;
}

.swal-title {
  font-size: 1.6rem !important;
  font-family: $baseFont !important;
}

.swal-icon--success__hide-corners {
  background-color: transparent;
}

.swal-icon--success:after,
.swal-icon--success:before {
  background: transparent;
}

.ant-upload {
  .ant-upload-drag {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    background: #fafafa;
    // border: 1px dashed #d9d9d9;
    border: 1px #d9d9d9 !important;
    border-radius: 5px !important;
    cursor: pointer;
    transition: border-color 0.3s;
  }
}

.apexcharts-canvas {
  margin: 0 auto;
}

textarea::placeholder {
  @include theme() {
    color: theme-get("inputborder1");
  }

  font-family: $baseFont !important;
}

tr.ant-table-placeholder {
  background-color: transparent !important;

  .ant-empty-description {
    @include theme() {
      color: theme-get("titlehead");
    }
  }
}

.fontFamilyText {
  font-family: $baseFont !important;

  @include theme() {
    color: theme-get("darkWhite");
  }
}

.apexcharts-legend-text {
  font-family: $baseFont !important;
  font-weight: 600;
  margin-bottom: 4px !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $CommonButton !important;
  border-color: $CommonButton !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: $CommonButton !important;
}

.ant-input[disabled] {
  @include theme() {
    background-color: theme-get("selectHoverableBg") !important;
  }
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  @include theme() {
    border-color: black !important;
  }
}

.anticon-gift svg {
  @include theme() {
    fill: theme-get("darkWhite") !important;
  }
}

.ant-table-tbody>tr.ant-table-row-selected>td {
  background-color: transparent !important;
}

// .button:hover {
//   background: orange;
// }

.swal-button:not([disabled]):hover {
  background-color: #d3d3d3;
  opacity: 0.9;
}

// body {
//   // .subitems {
//   //   height: 30px !important;
//   //   line-height: 30px !important;
//   //   padding-left: 0px;
//   // }
// }

.drop_icon {
  .ant-dropdown-trigger {
    height: 33px;
    border-radius: 8px;
    background-color: $CommonButton !important;
    color: #181a20 !important;
  }
}

.ant-dropdown-menu-item {
  padding-left: 20px !important;

  &:hover {
    @include theme() {
      background-color: theme-get("securityBtn");
    }
  }

  @include theme() {
    background-color: theme-get("securityBtn");
  }

  @include theme() {
    color: theme-get("darkWhite");
  }
}

.ant-menu .ant-menu-root .ant-menu-vertical .ant-menu-light {
  background-color: transparent !important;
}

#body {
  .trsp_itme {
    background-color: transparent !important;
    border-right: 0;
    margin-top: 10px !important;
  }

  .subitems {
    padding-left: 7px !important;
    list-style: none !important;
    margin-bottom: 0px !important;

    &:hover {
      background-color: #848e9c !important;
      color: #000 !important;
    }

    &-only-child {
      &::before {
        position: static !important;
      }
    }
  }
}

.lightTheme {
  .detailCardicon {
    svg {
      path {
        stroke: #000 !important;
      }
    }
  }
}

.ant-table-cell-row-hover {
  background-color: transparent !important;
}

.popoverClassCustom .ant-dropdown-menu-item {
  padding: 5px 8px !important;
}

.ant-radio-wrapper {
  @include theme() {
    color: theme-get("whiteblackBase");
  }
}

.userAccess_head_right {
  .inputLayout {
    width: 250px;

    input {
      width: 200px;
    }
  }
}

.assigned {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 60px;

  b {
    padding-right: 5px;
  }
}

.mb-2 {
  margin-bottom: 20px;
}

.center {
  display: block;
  text-align: center;
}

.errorItem {
  position: absolute;
  bottom: -20px;
  color: red;
}

.backBtn {
  margin-top: 20px;

  button {
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    cursor: pointer;
    white-space: nowrap;

    span {
      margin-right: 3px;
    }
  }
}

.back {
  align-items: center;
  justify-content: space-between !important;
  margin-top: 20px;
  gap: 10px !important;

  .backBtn {
    margin: 0;
  }
}