@import "../../Assets/Styles/_mixins.scss";
@import '../../Assets/Styles/_vars.scss';

.heading_card {
    font-size: 24px;

    @include theme() {
        color: theme-get("darkWhite");

    }
}

.mainCard {
    &__cardsgroup {
        display: flex;
        gap: 20px;

        @media (max-width:1129px) {
            flex-wrap: wrap;
        }
    }

    &__currency_check {
        h3 {
            // margin-top: 50px;
            font-size: 24px;

            @include theme() {
                color: theme-get("darkWhite");

            }
        }

        .ant-checkbox-group-item {
            span {
                @include theme() {
                    color: theme-get("darkWhite");

                }
            }
        }

        .input_fildes {
            margin-top: 50px;

            @media (max-width:991px) {
                label {
                    margin-top: 7px;
                }
            }
        }
    }
}


.carddetail {
    max-width: 300px;
    width: 100%;
    background: transparent linear-gradient(180deg, #F1F5F9 0%, #F1F5F9 100%) 0% 0% no-repeat padding-box;
    padding: 20px;
    border-radius: 20px;

    @media (max-width:767px) {
        margin: auto;
        max-width: 100%;
    }

    h2 {
        text-align: center;
    }

    h3 {
        margin-bottom: 20px;
    }

    span {

        img {
            height: 25px;
            width: 25px;
            margin-right: 10px;
        }

    }

}

.ant-checkbox-group-item {
    margin-right: 50px !important;
}

.add_righttext {
    display: flex;
    justify-content: space-between;
}

.modalchaqes {
    .modal_btns {
        display: flex;
        gap: 20px;
        justify-content: center;
        margin: 30px 0;

        button {
            background-color: #bdbec2;
            max-width: 150px;
            width: 100%;
            height: 40px;
            border-radius: 5px;

            &:hover {
                border: 0;
                color: #000;
            }
        }
    }

    .ant-modal-footer {
        display: none;
    }

    .ant-modal-content {
        border-radius: 5px;

    }
}

.update_btn {
    margin: auto;
    display: block;
    // height: 40px;
    max-width: 150px;
    //  background-color: gray;
    margin-top: 30px;
    width: 100%;
    border-radius: 5px;
    border: 0;
    color: #fff;
    cursor: pointer;
    font-weight: 400;
    font-size: 18px;

}