@import "../../Assets/Styles/_mixins.scss";
@import "../../Assets/Styles/_vars.scss";
.assetIssuanceComp{
   
    .yellowBtn{
        height: 100%;
        width: 100px;
    }
}
.searchSec{
    display: flex;
    gap: 20px;
    justify-content: end;
    margin-bottom: 30px;
}
h4{
    @include theme() {
        color: theme-get("securitypage1");
      }
}