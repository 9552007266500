@import "../../Assets/Styles/_mixins.scss";
@import "../../Assets/Styles/_vars.scss";
div {
  @include theme() {
    background-color: theme-get("primaryBg");
  }
}
.loginScreen {
  width: 100%;
  &__logInContact {
    h2 {
      @include theme() {
        color: theme-get("titlehead");
      }
      opacity: 1;
      @include fluid-type(320px, 1920px, 15px, 30px);
    }
  }
  &__multiBtn {
    display: flex;
  }
  &__innerInput {
    padding-bottom: 26px;
  }
  &__alink {
    float: right;
    padding-top: 10px;
    @include theme() {
      color: theme-get("titlehead");
    }
  }
  &__logRegBtn {
    display: flex;
    gap: 20px;
  }

  &__iconBtn {
    &__addPadd {
      margin-top: 25px;
      .leftIcon {
        margin-right: 10px;
      }
    }
  }
  &__selectInput {
    display: flex;
    gap: 10px;
    .inputLayout {
      width: 100%;
    }
  }
  &__selectlabel {
    letter-spacing: 0px;
    @include theme() {
      color: theme-get("titlehead");
    }
    opacity: 1;
    width: 100%;
    text-align: left;
    @include fluid-type(320px, 1920px, 12px, 14px);
    display: flex;
    padding-bottom: 10px;
  }
  &__logBtn {
    background: transparent !important;
    @include theme() {
      color: theme-get("titlehead") !important;
    }
    margin-bottom: 26px;
    width: auto !important;
    gap: 28px;
    margin-right: 30px;

    &:hover {
      @include theme() {
        background-color: theme-get("logbtnhover") !important;
      }
      @include theme() {
        color: theme-get("titlehead") !important;
      }
    }
  }
  &__activeBtn {
    @include theme() {
      background-color: theme-get("logbtnhover") !important;
      &:focus {
        box-shadow: unset;
        border: 0;
        @include theme() {
          color: theme-get("titlehead") !important;
        }
      }
    }
  }
  &__leftCol {
    @media (max-width: 767px) {
      display: none !important;
    }
  }
  &__rytCol {
    @media (max-width: 767px) {
      width: 100%;
      max-width: 100% !important;
      flex: 0 0 100% !important;
    }
  }
}

