@import "../../Assets/Styles/_mixins.scss";
@import "../../Assets/Styles/_vars.scss";
.cardIssuance {
  @include theme() {
    background: theme-get("headerBackground");
    color: theme-get("whiteblackBase");
  }
  // max-width: 420px;
  width: 100%;
  border-radius: 5px;

  .stageNameOuter {
    background: $CommonButton;
    border-radius: 5px 5px 0 0;

    .proposalType {
      @include theme() {
        background-color: theme-get("headerBackground");
      }
      text-align: center;
      padding: 3px;
      border-radius: 8px;
      max-width: 80px;
      width: 100%;
    }
  }
  .stageName {
    padding: 32px 40px 22px;

    .asset_name {
      display: flex;
      justify-content: space-between;
      button {
        cursor: pointer;
        color: #000;
      }
    }
    .iconss {
      display: flex;
      gap: 10px;
    }
  }
}

.borderBtnImg {
  padding: 32px 40px 22px;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid $CommonButton;
  &:last-of-type {
    border-bottom: none;
  }
}
.borderouter {
  margin: 30px;
  border-radius: 20px;
}
.detailsSec {
  display: flex;
  gap: 20px;
  align-items: center;
  flex-flow: wrap;
  @media (max-width: 1600px) {
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    &:nth-child(2) {
      width: 115px;
    }
    h4 {
      @media (max-width: 1600px) {
        width: 107px;
      }
    }
  }
}
.viewSec{
  @media (max-width: 1600px) {
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    &:nth-child(2) {
      width: unset;
    }
    h4 {
      @media (max-width: 1600px) {
        width: unset;
      }
    }
  }
}

.borderItem {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  max-width: 306px;
  width: 100%;
  padding: 20px 10px;
  border-radius: 10px;

  @include theme() {
    background-color: theme-get("headerBackground");
  }
  > div {
    display: flex;
    gap: 5px;
    justify-content: space-between;
  }

  p {
    @include theme() {
      color: theme-get("whiteblackBase");
    }
    margin: 0;
    white-space: nowrap;
  }
}
.pieChartSec {
  display: flex;
  gap: 30px;
}
.expired-status {
  background-color: #3d3d3d;
  color: white !important;
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  cursor: pointer !important;
}
.completed-status {
  background-color: green;
  color: white !important;
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  cursor: pointer !important;
}
.pending-status {
  background-color: rgb(121, 167, 182);
  color: white !important;
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  cursor: pointer !important;
}
.overviewTabs {
  .ant-tabs > .ant-tabs-nav {
    padding-left: 30px !important;
  }
  .transactionItems {
    padding: 45px 30px;
  }
}

.font-weight {
  font-weight: 500;
}
