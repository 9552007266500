@import "../../Assets/Styles/_mixins.scss";
@import "../../Assets/Styles/_vars.scss";

textarea.ant-input {
  background-color: transparent;

  @include theme() {
    border-color: theme-get("inputborder");
    color: theme-get("whiteblackBase");
  }

  border: 1px solid;
  border-radius: 10px;
  background-color: transparent;
  padding-top: 10px;

  &:focus {
    box-shadow: none;
  }

  &:hover,
  &:focus,
  &:focus-visible,
  &:focus-within {
    outline: none !important;
    box-shadow: none !important;
  }
}

.annoucements {
  &_block {
    h2 {
      @include theme() {
        color: theme-get("headerDropdownTxt") !important;
      }

      @include fluid-type(320px, 1920px, 16px, 24px);
      font-weight: 600;
      margin-bottom: 20px;
    }

    button {
      max-width: 200px;
      width: 100%;
      display: block;
      margin-top: 20px;
    }

    h3 {
      @include theme() {
        color: theme-get("headerDropdownTxt") !important;
      }

      @include fluid-type(320px, 1920px, 14px, 16px);
      font-weight: 500;
      margin-bottom: 10px;
    }

    &_checkboxCard {
      padding: 20px 15px;

      @include theme() {
        background-color: theme-get("headerBackground");
      }

      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &_multiData {
      .anticon {
        @include theme() {
          color: theme-get("headerDropdownTxt") !important;
        }
      }
    }
  }

  table {
    .deleteBtn {
      margin: 0;

      .anticon {
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
}

.mt40 {
  margin-top: 40px;
}

.mt20 {
  margin-top: 20px;
}

.textedit {
  font-weight: 400;
  cursor: pointer;
  @include theme() {
    color: theme-get("headerDropdownTxt");
  }
  transition: 0.3s all;
  &:hover {
    color: $CommonButton;
  }
}

.texteditLight {
  font-weight: 450;

  @include theme() {
    color: theme-get("selectBorder");
  }
}
