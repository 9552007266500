@import "../../Assets/Styles/_vars.scss";
@import "../../Assets/Styles/_mixins.scss";

.pagenotfound {
    height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  @include theme() {
    color: theme-get("whiteblackBase");
  }
  font-size: 14px;
  font-weight: 500;
}
