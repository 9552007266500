@import "../../Assets/Styles/_vars.scss";
@import "../../Assets/Styles/_mixins.scss";
.ReferralDetails-Top {
  display: flex;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 20px;
  }
  @media (max-width: 720px) {
    gap: 20px;
  }
  button {
    padding: 0px 15px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 400;
    transition: all 0.25s;
    border: none;
    box-shadow: none;
    height: 45px;
    cursor: pointer;
    white-space: nowrap;
    @media (max-width: 600px) {
      width: 100%;
      gap: 20px;
    }
  }
}
.referralDetails-RightSide {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}
.ReferralDetails-card {
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    gap: 20px;
    width: 100%;
  }
}
.referralDropdown {
  .ant-dropdown-trigger {
    height: 45px;
    border-radius: 8px;
    background-color: $CommonButton !important;
    color: #181a20 !important;
    font-size: 16px;
  }
  button {
    height: 45px;
    font-size: 16px !important;
  }
}
.generalCarde {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  gap: 20px;

  .general {
    max-width: 200px;
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    margin: 0;
    @media (max-width:767px) {
    max-width: 100%;

    }
    ul,
    p {
      margin: 0;
    }
  }
}
.referralsEarned p {
  margin-bottom: 14px !important;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  // width: 100%;
}
.applicationBtn{
  button{
    width: 100%;
    height: 30px;
    font-size: 14px;
  }
}
.heading-type{
  color: #605c5c;
}
.ml-50{
  margin-left: 50px;
}
.disabled {
  &.disabled {
      opacity: 0.4;
      cursor: not-allowed;
  }
}
.right-text{
  margin-right: 15px;
  @include theme() {
    color: theme-get("headerDropdownTxt");
  }
}
.download_btn .fontFamilyText{
  color: #181a20!important;
}
.no_button{
  margin-right: 10px;
  span{
    color: black;
  }
}
.yes_button{
  span{
    color: black;
  }
}
.referral_detail{
  margin-top: 30px;
}