@import "../../Assets/Styles/_mixins.scss";
@import "../../Assets/Styles/_vars.scss";

.header {
  padding: 10px 23px 10px 60px;
  justify-content: space-between;
  border-bottom: 1px solid;
  @include theme() {
    border-color: theme-get("sidebarRightBorder") !important;

  }
  @media (max-width: 1199px) {
    padding: 15px;
  }

  @media (max-width: 767px) {
    padding: 15px;
  }

  &__left {
    display: flex;
    align-items: center;
    gap: 26px;
    justify-content: flex-start;
    @media (max-width: 1199px) {
     gap: 15px;
    }
    &__logoBrand {
      max-width: 100px;
      width: 100%;
      display: flex;
      align-items: center;
      img {
        max-width: 90px;
        width: 100%;
      }
    }
  }
  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
  }
  &__themeToggleIcon {
    background-color: transparent;
    border: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 15px;
  }
  &__fixWidthIcon {
    display: flex;
    align-items: center;
    height: 17px;
    width: 17px;
    transition: 0.3s all;
    cursor: pointer;
    &:hover {
      svg path {
        transition: 0.3s all;
        fill: $CommonButton;
      }
    }
  }

  &__toggleStyle {
    display: flex;
    align-items: center;
    &__icon {
      display: flex;
      align-items: center;
      margin-left: 15px;
      width: 35px;
    }
  }

  &__iconStyles {
    svg {
      transition: 0.3s all;
      &:hover {
        fill: $CommonButton;
      }
    }
    svg path {
      @include theme() {
        color: theme-get("darkWhite") !important;
      }
    }
  }
  &__flexCenter {
    display: flex;
    align-items: center;
    margin-left: 15px;
  }
}

svg.customColorToggle #Path_46078 {
  @include theme() {
    fill: theme-get("ProfileSvgBg") !important;
  }
}
svg.customColorToggle #Path_45869 {
  fill: transparent !important;
}
.customColorToggle {
  svg g rect,
  path {
    @include theme() {
      fill: theme-get("ProfileSvgBg") !important;
    }
  }
}

// Drawer css//
.header {
  &__drawer {
    &__icons {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;
      @media (max-width:991px) {
        width: 30px;
        margin-left: auto;
      }
    }
  }
}
.ant-drawer-content {
  @include theme() {
    background-color: theme-get("bgSection");
  }
}

//setting button css//
.header {
  &__settingIconOuter {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__settingIconStyle {
    background-color: transparent;
    border: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 15px;
    .settingIcon {
      cursor: pointer;
      transition: 0.3s all;
      &:hover {
        color: $CommonButton !important;
      }
    }
  }
}




.settingExchangeToggle {
  .setting__Exchange__ToggleMain {
    display: flex !important;
    box-shadow: rgb(24 26 32 / 10%) 0px 0px 1px, rgb(71 77 87 / 16%) 0px 16px 32px, rgb(24 26 32 / 16%) 0px 8px 16px;
    flex-direction: column;
    position: fixed;
    right: 25px;
    z-index: 100;
    top: 80px;
    border-radius: 4px;
    width: 344px;
    height: auto;
    font-size: 14px;
    font-weight: 500;
    padding: 16px;
    border: 1px solid;
  }
}
.setting__Exchange__ToggleMain {
  display: none !important;
  @include theme() {
    background-color: theme-get("bgSection") !important;
    color: theme-get("darkWhite") !important;
    border-color: theme-get("sliderPointsBorder") !important;

  }
}


.ant-drawer-body {
  @media (max-width:991px) {
  padding: 15px;
  }
}
.exchange {
  .header__settingIconOuter {
    display: block;
  }
}
.header__settingIconOuter {
  display: none;
}

//Links WithoutDropdown //

.headerLinkStyles {
  display: flex;
  align-items: center;
  gap: 14px;
  @media (max-width: 1199px) {
    gap: 12px;
   }
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    margin-bottom: 8px;
    
  }
  &__links {
    display: flex;
    align-items: center;
    @include theme() {
      color: theme-get("darkWhite");
    }
    @include fluid-type(320px, 1920px, 12px, 14px);
    font-weight: 500;
    transition: 0.3s all;
    &:hover {
      color: $CommonButton;
      }
    @media (max-width: 991px) {
      padding: 15px !important;
      width: 100%;
     
    }
    &:hover {
      opacity: 0.7;
     }
    svg path {
      @include theme() {
        fill: theme-get("darkWhite");
      }
    }
    &--borders {
      border-left: 1px solid #b7bdc6;
      border-right: 1px solid #b7bdc6;
      padding: 0 10px;
      @media (max-width: 991px) {
        border: 0;
      }
    }
    &--activebtn {
      border-radius: 4px;
      background-color: $yellowDarkBg;
      padding: 4px 10px;
      @include theme() {
        color: theme-get("darkWhiteReverse");
      }
      @media (max-width: 991px) {
        &:hover {
          @include theme() {
            color: theme-get("darkWhite");
          }
        }
      }
    }
  }
}