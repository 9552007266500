@import "../../Assets/Styles/_mixins.scss";
@import "../../Assets/Styles/_vars.scss";
.selectores {
  margin-bottom: 20px;
}
.addButton {
  margin: 20px 0;
  text-align: left;
  .yellowBtn {
    width: 200px;
    height: 44px;
  }
}
h2 {
  font-size: 18px;
  font-weight: 500;

  @include theme() {
    color: theme-get("whiteblackBase");
  }
}
.actionOptions,
.deleteBtn {
  display: flex;
  gap: 20px;
  justify-content: center;
}
.tableIconStyle {
  max-width: 800px;
  min-width: 100%;
  .fontFamilyText {
    white-space: break-spaces;
  }
}

.upload {
  margin: 20px 0 40px 0;

  .upload-list-inline {
    @media (max-width: 500px) {
      flex-wrap: wrap;
    }
    .ant-upload-list {
      max-width: 250px;
      width: 100%;
    }
    .ant-upload {
      width: 212.5px;

      .ant-btn {
        border: unset;
        padding: 4px 50px;
        border-radius: 5px;
        color: #000;
        background-color: $CommonButton;
      }
    }

    .ant-btn:hover {
      @include theme() {
        color: theme-get("whiteblackBase");
      }
    }
    .ant-btn:active,
    :focus {
      color: #000 !important;
    }
  }
}
.errTxt {
  color: red;
}
.update_operation {
  display: flex;
}
.cancelButton {
  margin-left: 10px;
}
.AssetTable {
  label {
    margin: 0 !important;
  }
  .actionOptions {
  }
  th {
    &:last-child {
      text-align: center;
    }
  }
}
