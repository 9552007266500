@import "../../Assets/Styles/_mixins.scss";
@import "../../Assets/Styles/_vars.scss";
// .textAreaStyle {
//     margin: 20px 0;

// }
.ck-content {
  height: 300px;
}
.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable, .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  border-color: var(--ck-color-base-border);
  @include theme() {
    background: var(--darkWhiteReverse);
    color: var(--darkWhite);
  }
}
